<template>
  <div class="page_help_center right_connent">
    <div class="title">帮助中心</div>
    <tab_comm :tab_list="tab_list" @tab_index="tab_index" />
    <div class="connent_wrap" v-if="current_index == 0">
      <div v-for="(item, index_1) in help_list" :key="index_1">
        <div
          class="item_li"
          v-for="(item1, index_2) in item.child_data"
          :key="index_2"
          @click="go_help_detail(item1)"
        >
          {{ item1.feedback_name }}
        </div>
      </div>
    </div>
    <div class="connent_wrap" v-if="current_index == 1">
      <div class="tab_wrap">
        <div
          :class="['item_wrap', index == active_index ? 'active' : '']"
          v-for="(item, index) in help_list"
          :key="index"
          @click="change_tab2(index)"
        >
          <img :src="item.feedback_icon" alt="" />
          <p>{{ item.feedback_name }}</p>
        </div>
      </div>
      <div class="connent_tab">
        <div
          class="item_li"
          v-for="(item1, index) in help_list_children"
          :key="index"
          @click="go_help_detail(item1)"
        >
          {{ item1.feedback_name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tab_comm from "@/components/tab_comm.vue";
import api from "@/config/api.js";
import { mapActions } from "vuex";
export default {
  //   name: "my_order",
  data() {
    return {
      tab_list: [
        {
          id: 0,
          name: "常见问题",
        },
        {
          id: 1,
          name: "全部问题",
        },
      ],
      current_index: 0,
      help_list: [],
      active_index: 0,
      help_list_children: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    ...mapActions(["setHelpData"]),
    tab_index(tab_index) {
      this.current_index = tab_index;
    },
    change_tab2(index) {
      this.active_index = index;
      // console.log(this.help_list[0]);
      this.help_list_children = this.help_list[index].child_data;
    },
    go_help_detail(item) {
      this.setHelpData(item);
      // console.log(item);
      this.$router.push(`/mine/help_detail?item=7`)
    },
    async getData() {
      try {
        const res = await api.help_list();
        if (res.code == 0) {
          let data = res.result;
          this.help_list = data;
          this.help_list_children = this.help_list[0].child_data;
          // console.log(data);
          // this.my_live_list = data;
          // console.log(this.my_live_list);
          // if (data.length == 0) {
          // } else {
          // }
        } else {
          this.$message(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  components: {
    tab_comm,
  },
};
</script>
<style scoped>
</style>

<style lang="less" scoped>
.right_connent {
  padding: 40px 40px;
  background: #ffffff;
  border-radius: 16px;
  width: 820px;
  min-height: 674px;
  .nav_name {
    font-size: 20px;
    
    font-weight: bold;
    color: #1f1f1f;
    line-height: 28px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e9e9e9;
  }
}
.page_help_center {
  background-color: #fff;
  text-align: left;
  .title {
    font-size: 20px;
    
    font-weight: bold;
    color: #1f1f1f;
    line-height: 28px;
    padding-bottom: 35px;
  }
  .connent_wrap {
    margin-top: 30px;
    border-top: 1px solid #e9e9e9;
    padding-top: 25px;
    .item_li {
      margin-bottom: 20px;
      font-size: 16px;
      
      font-weight: 400;
      color: #999999;
      cursor: pointer;
    }
    .item_li:hover {
      color: #479dff;
    }
    .tab_wrap {
      display: flex;
      align-items: center;
      .item_wrap.active {
        border: 1px solid #479dff;
        p {
          color: #479dff;
        }
      }

      .item_wrap:hover {
        border: 1px solid #479dff;
      }
      .item_wrap:hover p {
        color: #479dff;
      }
      .item_wrap {
        width: 170px;
        padding: 10px;
        // width: 280px;
        // height: 120px;
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        margin-right: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 22px;
        img {
          width: 50px;
          border-radius: 50%;
          height: 50px;
          margin-right: 10px;
        }
      }
    }
  }
  .connent_tab {
    padding-top: 30px;
  }
}
</style>